@import 'concert-ui-library/dist/styles/theme/styles/colors';
@import 'concert-ui-library/dist/styles/theme/styles/layout';

.top-nav {
    background-color: $primary-orange;

    .app-container {
        display: flex;

        &.empty {
            .p-menubar-button {
                display: none;
            }
        }
    }

    .logo-container {
        height: $header-height;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
    }

    .p-menubar {
        color: $primary-color-text;
        height: $header-height;
        border-radius: 0px;
        width: 100%;

        .p-icon {
            min-width: 24px;
            min-height: 24px;
            margin-right: 15px;
        }

        .p-menubar-start {
            text-align: left;
            flex-grow: 1;
        }

        .p-menubar-button {
            color: $primary-color-text;
            text-decoration: none;

            &:focus {
                box-shadow: none !important;
                outline: none !important;
            }
        }

        .p-menuitem.selected {
            .p-menuitem-link {
                background-color: $functional-light-grey;
            }
        }

        .p-menubar-root-list > .p-menuitem > .p-menuitem-content > .p-menuitem-link .p-menuitem-text {
            font-size: 1.2rem;
        }

        @media screen and (min-width: $lg) {
            .p-menubar-root-list > .p-menuitem > .p-menuitem-content > .p-menuitem-link .p-menuitem-text,
            &.p-menubar-mobile-active .p-menubar-root-list > .p-menuitem > .p-menuitem-content > .p-menuitem-link .p-menuitem-text {
                color: $primary-color-text;
            }

            .p-menubar-root-list > .p-menuitem.selected {
                .p-menuitem-link {
                    background: none;
                }

                .p-menuitem-text {
                    font-weight: 700;
                }
            }
        }
    }

    .logo {
        height: $header-height - 40px;
        pointer-events: none;
    }

    .p-avatar.p-avatar-lg {
        height: $header-height - 20px;
        width: $header-height - 20px;
    }
}
