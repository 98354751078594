@import 'concert-ui-library/dist/styles/theme/styles/layout';

.maintenance-container {
    max-width: auto;
    margin: auto;
    padding: 0.5rem;

    .p-card .p-card-body .p-card-content {
        padding: 0;
        text-align: center;
    }
    .p-card-body {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .pi-exclamation-triangle {
        font-size: 1rem;
        margin-right: .5rem;
    }
    h3 {
        font-size: 1rem;
    }
}