@import 'breadcrumb';

.message-container {
    .container-row {
        @media screen and (max-width: $sm) {
            &.self {
                .sender-container {
                    display: none;
                }
            }
        }
    }
}
